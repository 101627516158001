<template>
  <v-dialog v-model="$store.state.pictureLibrary" max-width="800">
    <v-container class="pa-4 text-center" style="background-color: white">
      <v-file-input
        v-model="file"
        :label="$t('upload_image')"
        accept="image/*"
        @change="uploadImage"
        :loading="loading"
        class="mx-auto"
        outlined
        style="max-width: 500px"
      ></v-file-input>
      <v-row class="fill-height" align="center" justify="center">
        <template v-for="(item, i) in items">
          <v-col :key="i" cols="12" md="4">
            <v-hover v-slot="{ hover }" style="cursor: pointer">
              <v-card :elevation="hover ? 12 : 2">
                <v-img
                  :src="item.url"
                  height="225px"
                  v-clipboard:copy="item.url"
                  v-clipboard:success="onCopy"
                >
                </v-img>
              </v-card>
            </v-hover>
            <p class="caption font-weight-medium font-italic mt-2">
              {{ item.created_at | moment }}
              <v-btn @click="dialogDelete(item, i)" icon>
                <v-icon>delete</v-icon>
              </v-btn>
            </p>
          </v-col>
        </template>
      </v-row>
      <v-dialog v-model="dialog" max-width="450">
        <v-card>
          <v-card-title class="headline">
            {{ $t("delete_confirm") }}
          </v-card-title>
          <v-card-text>
            {{ $t("delete_text") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
              :loading="loading"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="red darken-2"
              dark
              @click="deleteImage"
              :loading="loading"
            >
              {{ $t("delete") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-dialog>
</template>

<script>
import { upload } from "@/api/URLs";

export default {
  name: "PicturesLibrary",
  created() {
    this.getAll();
  },

  data: () => ({
    items: [],
    transparent: "rgba(255, 255, 255, 0)",
    loading: false,
    file: null,
    dialog: false,
    tempItem: null,
  }),
  methods: {
    getAll() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "get",
        url: upload,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) {
          self.items = response.data.data;
        }
        self.loading = false;
      });
    },
    uploadImage() {
      if (this.file === undefined || this.file === null) return;
      let formData = new FormData();
      formData.append("file", this.file);

      this.loading = true;
      let self = this;
      this.$http({
        method: "post",
        url: upload,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(function (response) {
        console.log(response);
        if (response.data.success) self.items.unshift(response.data.data);

        self.file = null;
        self.loading = false;
      });
    },
    dialogDelete(item, index) {
      this.tempItem = { id: item.id, index: index };
      this.dialog = true;
    },
    deleteImage() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "delete",
        url: upload + "/" + self.tempItem.id,
      }).then(function (response) {
        if (response.data.success) self.items.splice(self.tempItem.index, 1);
        console.log(response);
        self.dialog = false;
        self.loading = false;
      });
    },
    onCopy() {
      this.$store.commit("showToast", "copied_successfully");
    },
  },
};
</script>

<style scoped>
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
