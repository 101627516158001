<template>
  <div
    v-bind:id="editorId"
    class="unlayer-editor"
    v-bind:style="{ minHeight: minHeight }"
  ></div>
</template>

<script>
import { loadScript } from "./loadScript";
import { upload } from "@/api/URLs";
import Cookies from "js-cookie";

let lastEditorId = 0;
export default {
  name: "EmailEditor",
  props: {
    options: Object,
    projectId: Number,
    tools: Object,
    appearance: Object,
    locale: String,
    minHeight: {
      type: String,
      default: "500px",
    },
  },
  computed: {
    editorId() {
      return `editor-${++lastEditorId}`;
    },
  },
  created() {},
  mounted() {
    loadScript(this.loadEditor.bind(this));
  },
  methods: {
    loadEditor() {
      const options = this.options || {};
      if (this.projectId) {
        options.projectId = this.projectId;
      }
      if (this.tools) {
        options.tools = this.tools;
      }

      if (this.appearance) {
        options.appearance = this.appearance;
      }
      if (this.locale) {
        options.locale = this.locale;
      }
      /* global unlayer */
      this.editor = unlayer.createEditor({
        ...options,
        id: this.editorId,
        displayMode: "email",
      });

      this.editor.registerCallback("image", function (file, done) {
        var data = new FormData();
        data.append("file", file.attachments[0]);

        fetch(upload, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: data,
        })
          .then((response) => {
            // Make sure the response was valid
            if (response.status >= 200 && response.status < 300) {
              return response;
            } else {
              var error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            // Pass the URL back to Unlayer to mark this upload as completed
            done({ progress: 100, url: data.data.url });
          });
      });
      this.$emit("load");
    },
    loadDesign(design) {
      this.editor.loadDesign(design);
    },
    saveDesign(callback) {
      this.editor.saveDesign(callback);
    },
    exportHtml(callback) {
      this.editor.exportHtml(callback);
    },
  },
};
</script>

<style scoped>
.unlayer-editor {
  flex: 1;
  display: flex;
}
</style>
