<template>
  <div class="mt-3">
    <pictures-library />
    <div class="text-center">
      <v-btn
        v-clipboard:copy="'{{%NAME%}}'"
        v-clipboard:success="onCopy"
        v-text="$t('copy_name')"
        outlined
        color="primary"
        class="mx-1"
      >
        {{ $t("copy_name") }}
      </v-btn>

      <v-btn
        @click="$store.state.pictureLibrary = true"
        :loading="loading"
        outlined
        class="mx-1"
        color="primary"
      >
        <v-icon left>attachment</v-icon>
        {{ $t("choose_picture") }}
      </v-btn>
      <v-btn
        :loading="loading"
        outlined
        class="mx-1"
        @click="testDialog = true"
        color="primary"
      >
        {{ $t("send_test") }}
      </v-btn>
      <v-btn
        :loading="loading"
        class="mx-1"
        @click="save(false)"
        color="primary"
      >
        {{ $t("save") }}
      </v-btn>
      <span class="date-time" v-if="content.updated_at">
        {{ $t("updated_at") }} {{ content.updated_at | time }}
      </span>
    </div>
    <v-text-field
      class="mx-auto mt-5"
      style="max-width: 500px"
      :label="$t('subject')"
      v-model="content.subject"
      ref="subject_txt"
      outlined
      hide-details
    >
      <template v-slot:append-outer>
        <v-btn
          @click="save(true)"
          class="mx-2"
          color="primary"
          :loading="loading"
          fab
        >
          {{ $t("done") }}
        </v-btn>
      </template>
    </v-text-field>

    <email-editor
      class="mt-2"
      :style="'height:' + windowHeight + 'px;' + 'width:' + windowWidth + 'px'"
      :min-height="windowHeight + 'px'"
      ref="emailEditor"
      :locale="$root.$i18n.locale"
    />
    <v-dialog v-model="testDialog" width="500">
      <v-card>
        <v-card-title class="headline" v-text="$t('enter_test_email')" />
        <v-card-text>
          <v-form ref="email_form" lazy-validation>
            <v-text-field
              v-model="content.email"
              :label="$t('email')"
              :rules="emailRules"
              required
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="testDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" @click="sendTest">
            {{ $t("send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PicturesLibrary from "@/components/PicturesLibrary";
import EmailEditor from "@/components/email_editor/EmailEditor";
import { email, sendTestEmail } from "@/api/URLs";

export default {
  name: "NewEmail",
  components: { PicturesLibrary, EmailEditor },
  data() {
    return {
      content: {
        email: null,
        subject: null,
        html: null,
        design: {},
      },
      loading: false,
      windowHeight: null,
      windowWidth: null,
      testDialog: false,
      emailRules: [
        (v) => !!v || this.$t("email_required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("email_valid"),
      ],
    };
  },
  created() {
    if (this.$route.params.id != null && this.$route.params.id > 0)
      this.getEmail();
  },
  methods: {
    getEmail() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "get",
        url: email + "/" + self.$route.params.id,
      }).then(function (response) {
        console.log(response);
        self.loading = false;
        if (response.data.success) {
          self.content = response.data.data;

          if (self.$refs.emailEditor.editor !== undefined) {
            self.$refs.emailEditor.editor.loadDesign(self.content.design);
          } else {
            setTimeout(
              () =>
                self.$refs.emailEditor.editor.loadDesign(self.content.design),
              3000
            );
          }
        } else self.$router.go(-1);
      });
    },
    onCopy() {
      this.$store.commit("showToast", "copied_successfully");
    },

    save(isDone) {
      this.loading = true;
      let self = this;
      this.$refs.emailEditor.editor.exportHtml((data) => {
        self.content.design = data.design;
        self.content.html = data.html;

        console.log(self.content);
        self
          .$http({
            method: "post",
            url: email,
            data: self.content,
          })
          .then(function (response) {
            if (response.data.success) {
              if (isDone) self.$router.go(-1);
              else self.content = response.data.data;
            }
            console.log(response);
            self.loading = false;
          });
      });
    },
    sendTest() {
      if (this.$refs.email_form.validate()) {
        this.loading = true;
        let self = this;
        this.$refs.emailEditor.editor.exportHtml((data) => {
          self.content.design = data.design;
          self.content.html = data.html;

          self
            .$http({
              method: "post",
              url: sendTestEmail,
              data: self.content,
            })
            .then(function (response) {
              console.log(response);
              if (response.data.success) {
                self.testDialog = false;
                self.$store.commit("showToast", "sent_successfully");
              }
              self.loading = false;
            });
        });
      }
    },
  },
  mounted() {
    this.windowHeight = window.innerHeight - 180;
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight - 180;
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style scoped></style>
